.instructors-wrapper {
    background: #e8edfb;
    border-radius: 0.6vw;
    margin-top: 3vw;
    padding: 2.5vw 4vw;
}
.instructors-wrapper h3 {
    font-family: Poppins;
    font-weight: 700;
    font-size: 2.8vw;
    color: #191d23;
    text-align: center;
    padding-top: 2vw;
}
.instructors-wrapper h4 {
    font-family: Poppins;
    font-weight: 700;
    font-size: 1.2vw;
    color: #4b4f60;
}
.instructors-job-detail-wrapper {
    display: flex;
}
.instructors-job-detail-wrapper img {
    padding-left: 1vw;
}
.instructor-job-role {
    font-family: Poppins;
    font-size: 1.16vw;
    color: #4b4f60;
    font-weight: 500;
    padding-bottom: 2vw;
}
.instructors-job-desc {
    font-family: Poppins-Regular;
    font-size: 1.3vw;
    color: rgba(25, 29, 35, 1);
    line-height: 2.4vw;
    text-align: justify;
}
.instructors-content-image-wrapper {
    display: flex;
    position: relative;
    margin-top: 2vw;
}
.instructors-content-wrapper {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 4vw 0vw;
    margin: 0 7vw;
}

.instructors-position-wrapper {
    width: 100%;
    padding: 1vw 0;
    padding-top: 0.5vw;
}
.instructors-position-wrapper img {
    width: 6vw;
    height: auto;
}
.instructors-job-details-wrapper {
    font-family: Poppins;
    font-size: 1vw;
    color: #4b4f60;
    padding-left: 1.6vw;
}
.instructors-img-wrapper > img {
    width: 100%;
}
.instructors-img-wrapper {
    width: 30%;
    background: #f7f8f9;
    margin-bottom: 5vw;
    margin-left: 5vw;
    padding: 1vw;
    border-radius: 1vw;
    padding-bottom: 2vw;
}
.instructor-details {
    margin: 0vw 2vw;
    position: absolute;
    bottom: 5vh;
    width: 22%;
    left: 3vw;
    padding: 1vw 1vw;
    background: #f7f8f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgba(25, 29, 35, 1);
    border-radius: 1vw;
}
.instructor-name-and-links {
    display: flex;
}

.instructor-ln {
    width: 1.2rem;
    height: 1.2rem;
    margin-left: 0.3vw;
    cursor: pointer;
}
.instructor-gmail {
    width: 1.2rem;
    height: 1.1rem;
    margin-left: 0.3vw;
    cursor: pointer;
}
@media (max-width: 600px) {
    .instructors-wrapper {
        background: none;
    }
    .instructors-wrapper h3 {
        font-size: 5.5vw;
        padding-top: 3.5vw;
    }
    .instructors-img-wrapper {
        width: 40%;
    }
    .instructors-content-image-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 4vw;
    }
    .instructor-details {
        bottom: 57%;
        width: 60%;
        left: 19%;
        padding: 1.5vw 0vw;
        background: #f7f8f9;
    }
    .instructor-details h4 {
        font-size: 3.5vw;
    }
    .instructors-job-details-wrapper {
        font-size: 3.1vw;
        margin: 0 1vw;
        padding-left: 0vw;
    }
    .instructors-content-wrapper {
        width: 90%;
        padding-left: 0vw;
    }
    .instructors-job-desc {
        padding-top: 6vw;
        font-size: 3.8vw;
        color: rgba(25, 29, 35, 1);
        line-height: 6vw;
    }
    .instructor-ln {
        margin-left: 1vw;
    }
    .instructor-gmail {
        margin-left: 1vw;
    }
    .instructors-job-details-wrapper {
        padding-left: 3vw;
    }
}
