.event-leads-modal-wrapper-bg {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.event-leads-modal-wrapper {
    position: relative;
    width: 25%;
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 2vw 1vw;
    pointer-events: auto;
}
.event-leads-modal-close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}
.event-leads-modal-heading {
    color: #4f516b;
    font-family: "Lato-Bold";
    margin: 3% 0;
    padding-left: 1.5vw;
}
.event-leads-modal-form {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 1vw;
}
.event-leads-modal-form input {
    background: #f9f8f8;
    border: 1px solid #e3e3e3;
    border-radius: 6px;
    width: 100%;
    padding: 1vw 0;
    color: #fff;
    text-indent: 10px;
    cursor: pointer;
    outline: none;
    color: #231f20;
    font-size: 1.2vw;
}
.event-leads-modal-form label {
    padding-bottom: 1vw;
}
.event-leads-modal-form button,
.event-leads-modal-form a {
    font-family: "Lato-Bold";
    background: linear-gradient(93.44deg, #444bab -6.8%, #3ca5c2 186.94%);
    border-radius: 6px;
    color: #ffff;
    cursor: pointer;
    text-align: center;
    font-family: Poppins-SemiBold;
    padding: 1vw 3vw;
    border: 1px solid #e2e2e2;
}

@media (max-width: 600px) {
    .event-leads-modal-wrapper-bg {
        height: 80%;
    }
    .event-leads-modal-wrapper {
        width: 75%;
        padding: 5%;
    }
    .event-leads-modal-form input {
        padding: 10px 0px;
        font-size: 12px;
    }
    .event-leads-modal-form button,
    .event-leads-modal-form a {
        padding: 5%;
        margin-top: 10px;
    }
}
