.common-section-title {
    font-family: Poppins;
    font-weight: 800;
    font-size: 2.8vw;
    color: #231f20;
    text-align: center;
    padding: 3vw 0;
}
@media (max-width: 600px) {
    .common-section-title {
        font-size: 5.4vw;
        padding: 10vw 0;
    }
}
