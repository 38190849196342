* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

* ::-webkit-scrollbar {
    width: 0px;
    display: none;
}

* ::-webkit-scrollbar-track {
    background: #fff;
    display: none;
}

* ::-webkit-scrollbar-thumb {
    background: #fff;
    display: none;
}

* ::-webkit-scrollbar-thumb:hover {
    background: #fff;
    display: none;
}

.header-sticky {
    position: sticky;
    top: 0;
    z-index: 4;
}
