.event-faq-wrapper {
    background: #fff;
    padding: 2vw 6vw;
}
.event-faq-wrapper h3 {
    font-family: Poppins;
    font-size: 2.8vw;
    text-align: center;
    color: rgba(25, 29, 35, 1);
    text-align: center;
    padding: 4vw;
    padding-top: 1vw;
}
.event-faq-register-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2vw 0;
    padding-bottom: 0;
}
.event-faq-register-btn button {
    background: #3a51a6;
    border: 1px solid #3a51a6;
    border-radius: 0.4vw;
    border: none;
    padding: 1vw 2vw;
    font-family: Poppins;
    font-weight: 600;
    font-size: 1.4vw;
    color: #fff;
}

@media (max-width: 600px) {
    .event-faq-wrapper h3 {
        font-size: 5vw;
        padding: 35px 0;
    }
    .event-faq-register-btn button {
        margin-top: 20px;
        padding: 10px 15px;
        font-size: 14px;
    }
}
