/* color: "white",
    background: "linear-gradient(93.53deg, #444BAB 0%, #416BB3 62.5%, #3CA5C2 100%)",
    fontFamily: "Poppins-SemiBold",
    textTransform: "capitalize",
    padding: "10px 16px",
    fontSize: "15px",
    whiteSpace: "noWrap", */

.action-button button {
    background: linear-gradient(93.53deg, #444bab 0%, #416bb3 62.5%, #3ca5c2 100%);
    font-family: "Poppins-SemiBold";
    text-transform: capitalize;
    padding: 1vw 1.5vw;
    font-size: 1.2vw;
    font-family: Lato-Bold;
    white-space: nowrap;
    border: none;
    color: #fff;
    border-radius: 0.4vw;
    cursor: pointer;
}
@media (max-width: 600px) {
    .action-button button {
        padding: 2vw 4vw;
        font-size: 4vw;
        border-radius: 1.5vw;
        margin-right: 2vw;
    }
}
