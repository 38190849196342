.community-wrapper {
    display: flex;
    margin: 4vw 0;
    padding: 2vw 4vw;
    background-color: #e8edfb;
    align-items: center;
    justify-content: center;
}
.community-content {
    padding: 4vw 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.community-content h1 {
    font-family: Poppins-Medium;
    color: #191d23;
    font-size: 2.2vw;
    padding-bottom: 1vw;
}
.community-content p {
    color: #191d23;
    text-align: center;
    font-family: Poppins-Regular;
    font-weight: 400;
    font-size: 1.3vw;
    padding-bottom: 2vw;
}
.join-community-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 0.4vw;
    font-family: "Poppins-Regular";
    font-weight: 600;
    color: #fff;
    padding: 1vw 3vw;
    font-size: 1.5vw;
    cursor: pointer;
    background-color: #3a51a6;
}
.community-image {
    width: 25%;
}
.community-image img {
    width: 100%;
}
@media (max-width: 600px) {
    .community-wrapper {
        flex-direction: column;
        padding: 7vw 2vw;
        margin: 8vw 0;
    }
    .community-image {
        width: 55%;
    }
    .community-content {
        padding: 2vw;
    }
    .community-content h1 {
        font-size: 6vw;
        text-align: center;
        padding-bottom: 3vw;
    }
    .community-content p {
        font-size: 3.7vw;
        padding-bottom: 5vw;
    }
    .join-community-btn {
        font-size: 3.7vw;
        width: 90%;
        padding: 2.5vw 2vw;
        border-radius: 2vw;
    }
}
