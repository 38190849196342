.who-is-it-for-wrapper {
    background: var(--Neutral-50, #f7f8f9);
}
.who-is-it-for-wrapper {
    padding: 4vw;
}
.who-is-it-for-heading {
    font-family: Poppins;
    font-weight: 800;
    font-size: 2.8vw;
    color: #231f20;
    text-align: center;
    padding: 3vw 0;
    padding-top: 0vw;
}
.py-2 {
    padding: 2vw 0 !important;
}
.type-of-audience-card {
    display: flex;
    width: 46%;
    padding: 1vw;
    align-items: center;
    background: var(--Primary-Blue-50, #e8edfb);
    border-radius: 0.7vw;
    margin: 0 2%;
    font-family: Poppins;
    font-weight: 500;
    color: #2f3043;
    margin-bottom: 2vw;
}
.type-of-audience-card img {
    padding-right: 1vw;
}
.type-of-audience-wrapper {
    display: flex;
    flex-wrap: wrap;
}
.type-of-audience-card p {
    font-family: Poppins-Regular;
    font-weight: 500;
    color: #2f3043;
    font-size: 1.3vw;
    margin-left: 0.5vw;
}
.audience-card-tick-mark img {
    width: 3vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media (max-width: 600px) {
    .type-of-audience-card {
        width: 100%;
        border-radius: 1.8vw;
        padding: 2vw;
        margin-bottom: 4vw;
    }
    .type-of-audience-card p {
        font-size: 3.8vw;
        padding-left: 2vw;
    }
    .who-is-it-for-wrapper {
        padding-bottom: 10vw;
        padding-top: 14px;
    }
    .who-is-it-for-heading {
        font-size: 5.5vw;
        padding: 30px;
    }
    .audience-card-tick-mark img {
        width: 7vw;
    }
}
