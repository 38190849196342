.refer-your-friend-banner-wrapper {
    background: linear-gradient(182.17deg, #d7edfd 1.82%, rgba(252, 254, 255, 0) 131.82%);
    padding: 0 6vw;
    padding-top: 5vw;
    display: flex;
    justify-content: space-around;
    padding-bottom: 4vw;
}
.refer-your-friend-intro-contents-wrapper {
    width: 50%;
}
.refer-banner-intro-img {
    width: 100%;
    height: auto;
}
.refer-banner-common-title {
    font-family: Lato-Regular;
    font-weight: 700;
    font-size: 2.3vw;
}
.black-banner-title {
    color: #231f20;
}
.coloured-banner-title {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(90.39deg, #444bab 1.92%, #00b7ce 93.95%);
    -webkit-background-clip: text;
    padding-top: 1vw;
    display: flex;
    
}
.gift-box{
    display: flex;
    width: 5%;
    margin-left: 1vw;
}
.refer-banner-primiary-desc,
.refer-banner-secondary-desc {
    font-family: Lato-Regular;
    font-weight: 400;
    color: #343a40;
    font-size: 1.4vw;
    padding: 2vw 0;
}
.gift-items-display-wrapper {
    background: linear-gradient(182.78deg, #ffe925 2.32%, #d3d3ce 199.01%);
    border-radius: 7px;
    padding: 1vw 0;
    text-align: center;
    font-family: Lato-Regular;
    font-weight: 700;
    font-size: 1.5vw;
}
.referal-code-content {
    font-family: Lato-Regular;
    font-weight: 700;
    font-size: 1.5vw;
    color: #231f20;
    padding-bottom: 2vw;
}
.referral-button-wrapper {
    padding-bottom: 2vw;
}
.copy-button{
    background: linear-gradient(91.41deg, #444bab 0%, #3aa2c1 99.71%);
    border-radius: 4px;
    color: #fff;
    font-family: Lato-Regular;
    font-weight: 700;
    font-size: 1.4vw;
    border: none;
    padding: 1vw 2vw;
    width:45%;
    cursor: pointer;
}
.refer-banner-referral-button {
    background: linear-gradient(91.41deg, #444bab 0%, #3aa2c1 99.71%);
    border-radius: 4px;
    color: #fff;
    font-family: Lato-Regular;
    font-weight: 700;
    font-size: 1.4vw;
    border: none;
    padding: 1vw 2vw;
    cursor: pointer;
}
.view-referal-code {
    color: #444bab;
    font-size: 1.4vw;
    font-family: "Lato-Bold";
    padding: 1vw 2vw;
    cursor: pointer;
}

.generate-referral-modal-wrapper-bg {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.generate-referral-modal-wrapper {
    position: relative;
    width: 25%;
    background: #fff;
    border-radius: 10px;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    /* padding: 3%; */
    padding: 2vw 1vw;
    pointer-events: auto;
}
.generate-referral-modal-close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}
.generate-referral-modal-heading {
    color: #4f516b;
    font-family: "Lato-Bold";
    margin: 3% 0;
    padding-left: 1.5vw;
}
.generate-referral-modal-form {
    display: flex;
    flex-direction: column;
    /* border: 1px solid #cecece;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12); */
    border-radius: 10px;
    padding: 1vw;
}
.generate-referral-modal-form input {
    background: #F9F8F8;
    border: 1px solid #e3e3e3;
    border-radius: 6px;
    width: 100%;
    padding: 1vw 0;
    color: #fff;
    text-indent: 10px;
    cursor: pointer;
    outline: none;
    color:#231f20;
}
.generate-referral-modal-form label {
    padding-bottom: 1vw;
}
.generate-referral-modal-form button {
    font-family: "Lato-Bold";
    background: linear-gradient(93.44deg, #444bab -6.8%, #3ca5c2 186.94%);
    border-radius: 6px;
    color: #ffff;
    cursor: pointer;
    text-align: center;
    font-family: Poppins-SemiBold;
    padding: 1vw 3vw;
    border: 1px solid #E2E2E2;
}
.view-gen-form-loader{
    position: absolute;
    left: 50vw;
    top: 35vw;
    cursor: auto;
}


@media (max-width: 600px) {
    .refer-your-friend-banner-wrapper {
        flex-direction: column;
    }
    .refer-your-friend-intro-contents-wrapper {
        width: 100%;
    }
    .refer-banner-common-title {
        font-size: 4.5vw;
        text-align: center;
        margin-left: 15px;
    }
    .refer-banner-primiary-desc,
    .refer-banner-secondary-desc {
        text-align: center;
        font-size: 3.2vw;
        padding: 5vw 0;
    }
    .gift-box > img {
        width: 4.5vw;
        padding-left: 0.5vw;
    }
    .gift-items-display-wrapper {
        padding: 3vw 0;
        font-size: 3.2vw;
    }
    .copy-button{
        width: 100%;
        padding: 3vw 0;
        font-size: 4vw;
    }
    .refer-banner-referral-button {
        width: 100%;
        padding: 3vw 0;
        font-size: 4vw;
    }
    .referal-code-content {
        text-align: center;
        font-size: 3.2vw;
        padding-bottom: 3vw;
    }
    .refer-banner-intro-img {
        padding: 6vw 0;
    }
    .view-referal-code{
        font-size: 14px;
        padding-top: 10px;
        text-align: center;
    }
    .generate-referral-modal-wrapper{
        width:50%;
        padding: 5%;
    }
    .generate-referral-modal-form input{
        padding: 10px 0px;
    }
    .generate-referral-modal-form button{
        padding: 5%;
        margin-top: 10px;
    }
}
