.learning-outcomes-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4vw;
    background: var(--Primary-Blue-50, #e8edfb);
}
.learning-outcomes-heading {
    font-family: Poppins;
    font-weight: 800;
    font-size: 3vw;
    color: #191d23;
    text-align: center;
    padding-bottom: 3vw;
}
.outcome-card-wrapper {
    display: flex;
    flex-wrap: wrap;
}
.outcome-card {
    display: flex;
    width: 47%;
    background: var(--Neutral-50, #f7f8f9);
    border-radius: 0.7vw;
    margin-bottom: 1.5vw;
    align-items: center;
    padding: 1vw 2vw;
    margin-right: 1vw;
}
.outcome-card-tick {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 0.7vw;
}
.outcome-card-tick img {
    padding-right: 0;
    width: 1.8vw;
}
.outcome-card p {
    font-family: Poppins-Regular;
    font-size: 1.3vw;
    color: #191d23;
    font-weight: 1.5vw;
}
@media (max-width: 600px) {
    .learning-outcomes-wrapper {
        padding: 0 6vw;
    }
    .outcome-card-wrapper {
        width: 100%;
    }
    .outcome-card {
        border-radius: 1.7vw;
        padding: 2vw;
        margin-bottom: 4vw;
        width: 100%;
    }
    .outcome-card p {
        font-size: 3.8vw;
        padding-left: 2vw;
    }
    .learning-outcomes-wrapper {
        padding-bottom: 5vw;
    }
    .learning-outcomes-heading {
        font-size: 5.5vw;
        padding: 35px;
    }
    .outcome-card-tick {
        display: flex;
        justify-content: flex-start;
    }
    .outcome-card-tick img {
        width: 6vw;
    }
}
