.workshop-career-benefit-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4vw;
}
.workshop-career-benefit-heading {
    font-family: Lato-Bold;
    font-weight: 800;
    font-size: 3vw;
    color: #231f20;
    text-align: center;
    padding-bottom: 3vw;
}
.workshop-career-benefit-card-wrapper {
    /* width: 60%; */
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex: 1;
}
.workshop-career-benefit-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #e7f5ff;
    border-radius: 0.7vw;
    margin: 1vw 0;
    align-items: center;
    padding: 2vw 1vw;
    width: 30%;
    text-align: center;
}
.workshop-career-benefit-card p {
    font-family: Lato-Bold;
    font-size: 1.3vw;
    color: #2f3043;
    font-weight: 1.5vw;
    margin-top: 10px;
}
.workshop-career-benefit-badge-icon {
    font-size: 2vw !important;
    color: #054c60;
}

@media (max-width: 600px) {
    .workshop-career-benefit-wrapper {
        padding: 0 6vw;
    }
    .workshop-career-benefit-card-wrapper {
        width: 100%;
    }
    .workshop-career-benefit-card {
        border-radius: 1.7vw;
        padding: 5vw 2vw;
        margin-bottom: 5vw;
        width: 100%;
    }
    .workshop-career-benefit-card p {
        font-size: 3.8vw;
        padding-left: 2vw;
    }
    .workshop-career-benefit-wrapper {
        padding-bottom: 5vw;
    }
    .workshop-career-benefit-heading {
        font-size: 5vw;
        padding: 35px;
    }
    .workshop-career-benefit-badge-icon {
        font-size: 6vw !important;
    }
}
