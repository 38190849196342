.intro-contents-wrapper {
    display: flex;
    flex-direction: column;
    width: 49%;
    margin-right: 8vw;
    padding-top: 1vw;
}
.student-registered {
    margin-top: 0.1vw;
    color: #10b981;
    font-family: Poppins-Medium;
}
.student-registered-wrapper {
    display: flex;
    align-items: center;
}
.student-registered-wrapper img {
    margin-right: 1vw;
}

.free-trail p {
    font-family: "Poppins";
    color: #272834;
    font-size: 1.1vw;
    font-weight: bold;
    margin-top: 0.7vw;
    padding-bottom: 1vw;
}

.intro-buttons {
    display: flex;
}
.intro-register-button,
.intro-curriculum-button {
    padding: 1vw 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2vw;
    font-family: Poppins;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    width: fit-content;
    font-weight: 500;
}
.intro-register-button {
    width: 35%;
    background: #3a51a6;
    margin-right: 0.7vw;
    color: #ffffff;
    font-weight: 500;
}
.intro-curriculum-button {
    background: #bbcaf3;
    width: 48%;
    color: #191d23;
}

.intro-heading {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 2.4vw;
    color: rgba(25, 29, 35, 1);
}

.intro-caption-subheading {
    font-family: "Poppins";
    color: rgba(25, 29, 35, 1);
    padding: 1.5vw 0;
    font-size: 1.4vw;
    font-weight: 400;
}

.time-and-requirement-wrapper {
    display: flex;
    padding: 1vw 0;
}
.time-and-requirement-wrapper > * + * {
    margin-left: 50px;
}

.introduction-wrapper {
    display: flex;
    background: #d6e6f2;
    padding: 4vw 6vw;
    margin-bottom: 5vw;
    position: relative;
}
.intro-list {
    display: flex;
    align-items: center;
}
.intro-images-point {
    margin-right: 1vw;
}
.intro-images-point img {
    width: 1vw;
}
.intro-list-para {
    font-size: 1.2vw;
    padding-bottom: 0.5vw;
    font-family: Poppins-Regular;
    color: rgba(0, 0, 0, 1);
}

.image-wrapper {
    width: 60%;
    margin-left: 10vw;
}

.companies-logo-and-topic {
    display: flex;
    flex-direction: column;
    width: 43%;
    background: #ffffff;
    padding: 1vw;
    border-top-left-radius: 1vw;
    position: absolute;
    bottom: 0vw;
    padding: 1.3vw;
    right: -0.1%;
}
.companies-logo-and-topic p {
    font-family: Poppins;
    font-size: 1.3vw;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 1vw;
}

.event-company-images {
    display: flex;
    justify-content: space-around;
    height: 5vh;
    margin-top: 0.7vw;
}
.company-logo-amazon {
    width: 15% !important;
}
.company-logo-linkedin {
    width: 18% !important;
}
.company-logo-flipkart {
    width: 21% !important;
}
.company-logo-uber {
    width: 13% !important;
}
.event-company-images img {
    margin-right: 1vw;
}
.event-intro-video-player {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1vw;
    cursor: pointer;
    height: 32vw;
    width: 50%;
}
.event-intro-banner-image-wrapper {
    width: 51%;
    height: 23vw;
    margin: auto;
}
.event-intro-banner-image-wrapper img {
    width: 100%;
    height: auto;
}

.video-player {
    flex-grow: 1;
    border: none;
    margin: 0;
    padding: 0;
    width: 22vw;
    border-radius: 1vw;
}

.referral-cashback{
    font-family: Poppins-Medium;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.0125em;
}
.cashback-amount{
    font-family: Poppins-Medium;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #F97316;
    letter-spacing: 0.0125em;
}

@media (max-width: 600px) {
    .introduction-wrapper {
        background: var(--Neutral-50, #f7f8f9);
        margin-bottom: 0vw;
    }

    .intro-contents-wrapper {
        width: 100%;
        margin-right: 1vw;
    }
    .intro-register-button,
    .intro-curriculum-button {
        width: 100%;
        font-size: 3.6vw;
        padding: 3.6vw 0vw;
        margin-bottom: 2vw;
    }
    .intro-buttons {
        display: flex;
        flex-direction: column;
        margin-bottom: 2vw;
    }

    .event-intro-video-player-mobile {
        display: flex;
        height: 60vh;
        margin-bottom: 3vw;
    }

    .intro-images-point img {
        margin-right: 1.5vw;
        width: 4.5vw;
    }
    .intro-list {
        display: flex;
    }
    .intro-list-wrapper {
        max-width: 600px;
        margin: 0 auto;
        width: 100%;
    }
    .intro-heading {
        font-size: 5.5vw;
        margin-bottom: 2.6vw;
        margin-top: 3vw;
        text-align: center;
        letter-spacing: 0.05rem;
    }
    .intro-caption-subheading {
        font-size: 4.2vw;
        margin-bottom: 3vw;
        text-align: center;
        font-family: Poppins-Medium;
    }

    .time-and-requirement-wrapper {
        justify-content: center;
        padding: 2vw 0;
    }
    .student-registered-wrapper {
        padding-bottom: 2vw;
    }
    .student-registered-wrapper img {
        width: 25%;
    }
    .student-registered {
        font-size: 3.4vw;
    }
    .free-trail p {
        font-size: 3.5vw;
    }
    .intro-list-para {
        font-size: 3.8vw;
        margin-bottom: 10px;
    }

    .video-player {
        width: 255px;
        margin: 0 auto;
    }
    .companies-logo-and-topic p {
        font-size: 3.8vw;
        text-align: center;
        margin-bottom: 4px;
    }
    .event-company-images {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: flex-end;
        margin-top: 2.7vw;
    }
    .event-company-images img {
        margin-right: 4vw;
        width: 25vw;
        margin-bottom: 2vw;
    }
    .company-logo-amazon {
        width: 25% !important;
    }
    .company-logo-linkedin {
        width: 31% !important;
    }
    .company-logo-flipkart {
        width: 33% !important;
    }
    .company-logo-uber {
        width: 20% !important;
    }

    .companies-logo-and-topic {
        background: var(--Neutral-50, #f7f8f9);
        width: 100%;
        margin-top: 4vw;
        border-top-left-radius: 0vw;
        position: relative;
        width: 100%;
        bottom: 0vw;
        padding: 4vw 3vw 10vw;
        right: 0;
    }
    .intro-list-wrapper {
        margin-top: 3.5vw;
    }
    .event-intro-banner-image-wrapper {
        width: 95%;
        margin-bottom: 15vw;
    }
}
