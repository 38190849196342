.testimonials-wrapper {
    padding: 4vw 6vw;
}
.certificates-wrapper {
    padding: 4vw 6vw;
}
.certificate-container {
    padding-top: 4vw;
}
.testimonials-heading {
    font-family: Poppins;
    font-weight: 800;
    font-size: 2.8vw;
    color: #191d23;
    text-align: center;
    padding-bottom: 3vw;
}
.section-title {
    font-family: Poppins;
    font-weight: 700;
    font-size: 2.4vw;
    color: #191d23;
    text-align: center;
    padding: 3vw 0;
}
.certificate-wrapper {
    display: flex;
    padding-top: 3vw;
}
.certificate-content-details-wrapper {
    display: flex;
    align-items: center;
    background: #fbfbfb;
    border: 1px solid #ded5d5;
    border-radius: 0.7vw;
    width: 100%;
    padding: 1vw;
    margin-bottom: 2.6vw;
}
.certificate-content-details-wrapper img {
    padding-right: 1vw;
}
.certificate-content-details-wrapper p {
    font-family: Poppins;
    font-weight: 700;
    font-size: 1.2vw;
}
.certificate-img-wrapper {
    width: 50%;
}
.certificate-features-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    flex: 1 1;
}
.testimonials-card-wrapper {
    display: flex;
    justify-content: space-between;
}

.success-stories-btn-wrapper {
    display: flex;
    justify-content: center;
    margin: 2vw 0vw;
}
.success-stories-btn-wrapper button {
    background: #fff;
    border: 3px solid rgba(68, 75, 171, 0.7);
    border-radius: 8px;
    color: #343a40;
    cursor: pointer;
    font-family: Poppins;
    font-size: 1.2vw;
    font-weight: 600;
    margin-right: 2vw;
    padding: 1vw 2vw;
}
.success-stories-card {
    width: 95% !important;
    border-radius: 20px;
    padding: 2vw;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
    margin: 2vw 1vw;
}
.success-stories-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.success-stories-card-quotes-img {
    width: 1.4rem;
    height: 1.1rem;
    margin-bottom: 2vw;
}
.success-stories-card-img {
    width: 6.5rem;
    height: 6.5rem;
}
.success-stories-card-txt {
    height: 9vw;
    margin-bottom: 2vw;
}
.success-stories-card-txt p {
    font-family: Poppins-Regular;
    font-size: 1vw;
    text-align: center;
    color: var(--Neutral-800, #191d23);
}
.success-stories-name-wrapper {
    display: flex;
    align-items: center;
    margin-top: 0.5vw;
}
.success-stories-name {
    color: var(--Neutral-800, #191d23);
    font-family: "Poppins-SemiBold";
    font-size: 1.1vw;
    margin-right: 0.5vw;
    margin-bottom: 0vw;
}
.success-stories-name-wrapper img {
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
}
.success-stories-company-logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.sde-text {
    color: #000;
    font-family: Poppins;
    font-weight: 800;
    font-size: 1.2vw;
}
.logo-wrapper {
    padding: 0.3vw 0.5vw;
}
.logo-wrapper img {
    width: 5rem;
    height: 2rem;
}
.google-wrapper img {
    width: 5rem;
    height: 2rem;
    margin-top: 0.3vw;
}
.amazon-wrapper img {
    width: 6rem;
    height: 2.5rem;
    margin-top: 0.5vw;
}
.paypal-wrapper img {
    width: 4rem;
    height: 2rem;
    margin-top: 0.3vw;
    margin-left: 0.3vw;
}
.success-stories-read-more-btn {
    display: flex;
    justify-content: center;
    margin-top: 4vw;
}
.success-stories-read-more-btn button {
    color: #fff;
    font-family: Poppins;
    background: #3940a0;
    cursor: pointer;
    border: 3px solid rgba(68, 75, 171, 0.7);
    border-radius: 8px;
    font-family: Poppins;
    font-weight: 600;
    font-size: 1.2vw;
    padding: 1vw 2vw;
    margin-right: 2vw;
}

@media (max-width: 600px) {
    .certificate-wrapper {
        flex-direction: column;
    }
    .certificate-img-wrapper {
        width: 100%;
        margin-bottom: 5vw;
    }
    .certificate-img-wrapper > img {
        width: 100%;
    }
    .certificate-features-wrapper {
        width: 100%;
    }
    .certificate-content-details-wrapper p {
        font-size: 3.6vw;
    }
    .certificate-container {
        padding-bottom: 5vw;
    }
    .testimonials-heading {
        font-size: 5.5vw;
        padding: 0;
    }
    .success-stories-btn-wrapper button {
        padding: 2.5vw 3vw;
        font-size: 3.5vw;
    }
    .success-stories-wrapper {
        margin-top: 6vw;
    }
    .success-stories-wrapper h1 {
        font-size: 5vw;
    }
    .success-stories-wrapper h2 {
        font-size: 5vw;
    }
    .success-stories-wrapper .slick-list {
        margin-left: 6vw;
        margin-right: 2vw;
    }
    .success-stories-card {
        height: 115vw;
        width: 100%;
        padding: 3vw;
        margin: 2vw;
        box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.1);
    }

    .slick-prev,
    .slick-next {
        display: none;
    }
    .success-stories-content {
        height: fit-content;
        justify-content: space-evenly;
        align-items: center;
    }
    .success-stories-card-txt {
        padding-left: 1vw;
        height: 40vw;
        margin-bottom: 2vw;
    }
    .success-stories-card-img {
        width: 7rem;
        height: auto;
        /* width: 8rem;
        height: 8rem; */
    }
    .success-stories-card-txt p {
        font-size: 3.6vw;
        padding: 2vw 0;
        line-height: 5vw;
    }
    .success-stories-name {
        font-size: 4.5vw;
    }
    .sde-text {
        font-size: 4vw;
    }
    .logo-wrapper img {
        width: 4rem;
        height: 1.5rem;
        padding: 0.5vw 0 0 1vw;
        margin-top: 0.5vw;
    }
    .google-wrapper img {
        width: 6rem;
        height: 2rem;
        margin-top: 1vw;
        margin-left: -0.2vw;
    }
    .amazon-wrapper img {
        width: 7rem;
        padding-top: 1.4vw;
    }
    .paypal-wrapper {
        margin-top: 2vw;
    }
    .paypal-wrapper img {
        margin-left: 1vw;
        height: 1.5rem;
        margin-top: -0.6vw;
    }
    .success-stories-company-logo-wrapper {
        margin-top: -1.5vw;
    }
    .success-stories-read-more-btn button {
        font-size: 2.9vw;
        border: 1px solid #444bab;
        padding: 2.5vw 5vw;
    }
    .success-stories-name-wrapper img {
        margin-top: 0.5vw;
    }
    .testimonials-card-wrapper {
        flex-direction: column;
    }

    .success-stories-card-quotes-img {
        width: 1.9rem;
        height: 1.5rem;
        margin: 7vw 4vw 3vw 4vw;
    }

    .success-stories-name-wrapper {
        margin-top: 2vw;
    }
    .success-stories-name {
        font-size: 3.5vw;
        margin-right: 1.2vw;
        margin-bottom: 1vw;
    }
    .success-stories-name-wrapper img {
        width: 1.25rem;
        height: 1.25rem;
        cursor: pointer;
    }
    .logo-wrapper {
        width: 40%;
    }
}
