.custom-accordion-item {
    background: #fff;
    margin-bottom: 1vw;
    padding: 10px 20px;
    border: 0.2px solid #faf7f7;
    border-radius: 0.5vw;
}

.custom-accodrion-title {
    color: #231f20;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.custom-accordion-item.custom-accordion-background {
    background: #fff;
}

.custom-accodrion-title h2 {
    font-family: Poppins-Semibold;
    font-size: 1.2vw;
    font-weight: 500;
}
.custom-accordion-answer {
    font-size: 1vw;
    font-weight: 400;
    font-family: Poppins-Regular;
}

.custom-accodrion-content {
    color: #231f20;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
    font-family: Poppins;
    font-size: 1.1vw;
}

.custom-accodrion-content.custom-accodrion-show {
    padding-top: 1.5vw;
    height: auto;
    max-height: 9999px;
    transition: all 1.2s cubic-bezier(0, 1, 0, 1);
}
.custom-accordion-wrapper {
    margin-bottom: 1.5vw;
}

@media (max-width: 600px) {
    .custom-accodrion-title h2,
    .custom-accodrion-content {
        font-size: 15px;
        padding: 0 0.5vw;
    }
    .custom-accodrion-title h2 {
        width: 90%;
    }

    .custom-accordion-wrapper {
        margin-bottom: 3vw;
    }
    .custom-accordion-item.custom-accordion-background {
        background-color: #fff;
    }
    .custom-accordion-item {
        background-color: #fff;
    }
    .custom-accordion-img-wrapper {
        width: 100%;
    }
    .custom-accordion-answer {
        font-size: 3.2vw;
    }
    .custom-accodrion-title span {
        width: 12px;
    }
    .open-close-img-faq {
        width: 10px;
    }
    .open-close-img-faq-up {
        width: 15px;
    }
}
