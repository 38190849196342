@font-face {
    font-family: "Lato-Bold";
    src: url("./Resources/Fonts/Lato-Bold.ttf");
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url("./Resources/Fonts/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: "Lato-Black";
    src: url("./Resources/Fonts/Lato-Black.ttf");
}
@font-face {
    font-family: "Lato-Italic";
    src: url("./Resources/Fonts/Lato-Italic.ttf");
}

@font-face {
    font-family: "Gilroy-Bold";
    src: url("./Resources/Fonts/Gilroy-Bold.ttf");
}

@font-face {
    font-family: "Gilroy-SemiBold";
    src: url("./Resources/Fonts/Gilroy-SemiBold.ttf");
}

@font-face {
    font-family: "Gilroy-Medium";
    src: url("./Resources/Fonts/Gilroy-Medium.ttf");
}

@font-face {
    font-family: "Gilroy-Regular";
    src: url("./Resources/Fonts/Gilroy-Regular.ttf");
}

@font-face {
    font-family: "Poppins-Regular";
    src: url("./Resources/Fonts/Poppins-Regular.ttf");
}

@font-face {
    font-family: "Poppins-Italic";
    src: url("./Resources/Fonts/Poppins-Italic.otf");
}
@font-face {
    font-family: Gilroy-Bold;
    src: url("./Resources/Fonts/Gilroy-Bold.ttf");
}
@font-face {
    font-family: Poppins;
    src: url("./Resources/Fonts/Poppins.ttf");
}
@font-face {
    font-family: Poppins-Medium;
    src: url("./Resources/Fonts/Poppins-Medium.ttf");
}
@font-face {
    font-family: Lato-Light;
    src: url("./Resources/Fonts/Lato-Light.ttf");
}
@font-face {
    font-family: Lato-Bold;
    src: url("./Resources/Fonts/Lato-Bold.ttf");
}
@font-face {
    font-family: Lato-Regular;
    src: url("./Resources/Fonts/Lato-Regular.ttf");
}

@font-face {
    font-family: Montserrat-Bold;
    src: url("./Resources/Fonts/Montserrat-Bold.ttf");
}
@font-face {
    font-family: Montserrat-Black;
    src: url("./Resources/Fonts/Montserrat-Black.ttf");
}
@font-face {
    font-family: Montserrat-SemiBold;
    src: url("./Resources/Fonts/Montserrat-SemiBold.ttf");
}
@font-face {
    font-family: Roboto-Light;
    src: url("./Resources/Fonts/Roboto-Light.ttf");
}
@font-face {
    font-family: Roboto-Bold;
    src: url("./Resources/Fonts/Roboto-Bold.ttf");
}
@font-face {
    font-family: Roboto-Regular;
    src: url("./Resources/Fonts/Roboto-Regular.ttf");
}
@font-face {
    font-family: Play;
    src: url("./Resources/Fonts/Play.ttf");
}
@font-face {
    font-family: Play-Regular;
    src: url("./Resources/Fonts/Play-Regular.ttf");
}
@font-face {
    font-family: Play-Bold;
    src: url("./Resources/Fonts/Play-Bold.ttf");
}
@font-face {
    font-family: Orienta;
    src: url("./Resources/Fonts/Orienta.ttf");
}
