.spinner {
    width: 100vw;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.infobox {
    font-family: Lato-Regular;
    display: flex;
    align-items: center;
    flex-direction: row;
    color: #717171;
    font-size: 1.2vw;
}

.infobox > img {
    margin-right: 0.5vw;
}

.infobox > div {
    font-size: 1.3vw;
}

@media (max-width: 600px) {
    .infobox > div {
        font-size: 3vw;
    }

    .infobox > img {
        margin-right: 2vw;
    }
}
