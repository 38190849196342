.footer-container {
    background-color: #181b43;
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    height: 412px;
}

.footer-wrapper {
    display: flex;
    gap: 56px;
    justify-content: space-between;
}

.footer-heading {
    color: #f8fafc;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    font-family: Gilroy-Bold;
}

.footer-links-container,
.footer-programs-container,
.footer-contacts-container {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.footer-link {
    color: #f8fafc;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    letter-spacing: 0.025em;
    cursor: pointer;
    font-family: 'Gilroy-Regular';
    font-weight: 300;
}

.footer-program-item {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}

.footer-program-image {
    position: relative;
    width: 116px;
    height: 64px;
}

.footer-container a{
    text-decoration: none;
}

.footer-program-image img{
    width: 116px;
}

.footer-program-description {
    max-width: 296px;
}

.footer-program-name {
    color: #f8fafc;
    font-size: 14px;
    text-align: left;
    line-height: 20px;
    font-family: "Gilroy-Bold";
    letter-spacing: 0.025em;
    font-weight: 600;
}

.footer-contact-section{
    max-width: 261px;
}

.footer-contact-item {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.footer-contact-icon {
    width: 24px;
    height: 24px;
}

.footer-contact-text {
    color: #f8fafc;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 20px;
    font-weight: 200;
    letter-spacing: 0.025em;
}

.footer-social-links {
    margin-top: 40px;
}

.footer-social-icons {
    display: flex;
    gap: 12px;
    margin-top: 12px;
}

.footer-social-item {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.footer-social-item img{
    width: 100%;
}

.footer-reserved-text h3{
    margin-top: 40px;
    color: #f8fafc;
    font-family: 'Gilroy-Regular';
    font-size: 14px;
    line-height: 20px;
    font-weight: 100;
    letter-spacing: 0.025em;
}

@media (max-width: 1300px) {
    .footer-last-div{
        max-width: 275px;
    }
    .footer-wrapper {
        gap: 20px;
    }
    .footer-container {
        height: auto;
    }
}

@media (max-width: 1023px) {
    .footer-wrapper {
        flex-wrap: wrap;
    }

    .footer-popular-programs {
        order: -1;
    }
}

@media (max-width: 640px) {
    .footer-popular-programs {
        order: -1;
        max-width: 381px;
    }   
}