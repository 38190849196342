.leaderboard-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4vw;
    background: #1e1e1e;
}
.top-three {
    color: #3ca5c2;
    font-family: "Lato-Bold";
    padding: 0.5vw;
    font-size: 2.4vw;
}
.leaderboard-wrapper-title {
    color: #ffffff;
    font-family: "Lato-Bold";
    padding: 0.5vw;
    font-size: 2.4vw;
}
.leaderboard-wrapper table {
    border-collapse: collapse;
    width: 75%;
}
.leaderboard-wrapper th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
    font-size: 1.5vw;
    color: #ffffff;
}
.row td {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
    font-size: 1.5vw;
    color: #ffffff;
}
.row-user td {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
    font-size: 1.5vw;
    color: #feeb40;
}
