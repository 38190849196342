.course-includes-wrapper1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding-bottom: 5vw;
}

.course-includes-title {
    text-align: center;
    font-family: Poppins;
    font-size: 2.8vw;
    padding: 3vw 0vw;
    font-weight: 800;
    color: #231f20;
}

.course-included-img-section {
    width: 51%;
    display: flex;
    justify-content: center;
}

.course-included-container {
    width: 60%;
}
.course-included-dropdown-img {
    width: 85%;
}
.download-curriculum-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1vw;
    border-radius: 6px;
    font-family: Poppins;
    margin-right: 0.5vw;
    font-size: 1.4vw;
    color: #3a51a6;
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
}

@media (max-width: 600px) {
    .course-includes-wrapper1 {
        margin-top: 25px;
    }
    .course-includes-wrapper-mobile {
        margin: 0 20px;
    }

    .course-included-container {
        width: 90%;
    }
    .course-includes-title {
        font-size: 5vw;
        padding: 25px;
    }
    .download-curriculum-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 3vw 1vw 0vw;
        width: 90%;
        font-size: 3.3vw;
    }
}
