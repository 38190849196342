.chat-with-expert {
    position: fixed;
    width: 10vw;
    height: 5vh;
    display: flex;
    padding: 20px 5px;
    gap: 0.5vw;
    align-items: center;
    justify-content: center;
    right: 2vw;
    bottom: 2vw;
    border-radius: 24px;
    border: 1px solid #50cc5d;
    z-index: 10000;
    cursor: pointer;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    background-color: #50cc5d;
    color: #fff;
}
.chat-with-expert > img {
    height: 25px;
    width: 25px;
}
.chat-with-expert-content {
    font-family: "Poppins-Regular";
    font-size: 15px;
}

@media (max-width: 600px) {
    .chat-with-expert {
        width: 120px;
        padding: 2px 10px;
        bottom: 20px;
        right: 25px;
    }
    .chat-with-expert > img {
        height: 15px;
        width: 15px;
    }
    .chat-with-expert-content {
        font-size: 14px;
    }
}
