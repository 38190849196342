.curriculum-accordion-item {
    margin-bottom: 1vw;
    padding: 10px 20px;
    border: 1px solid rgba(184, 192, 204, 1);
    background-color: #f7f8f9;
    border-radius: 5px;
}

.curriculum-accodrion-title {
    color: #4f516b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.curriculum-accodrion-with-dropdown {
    display: flex;
}
.curriculum-accodrion-with-dropdown span {
    margin-right: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.curriculum-open-heading {
    color: #3a51a6;
    font-family: Poppins-Medium;
    font-size: 1.3vw;
    font-weight: 500;
}

.curriculum-close-heading {
    font-family: Poppins-Medium;
    font-size: 1.3vw;
    color: #231f20;
    font-weight: 500;
}

.accordion-module {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 0.5vw 1.5vw;
    justify-content: center;
    border-radius: 27px;
    background: #e7eaee;
    color: #4b5768;
    font-family: Poppins;
    font-size: 0.9vw;
}

.accordian-point-img-wrapper {
    display: flex;
    align-items: flex-start;
}

.accordian-point-img-wrapper img {
    width: 3%;
    align-self: center;
    margin-right: 0.6vw;
}

.accordian-point-img-wrapper p {
    font-size: 1vw;
    font-family: Poppins-Regular;
    align-self: center;
}

.curriculum-accordion-answer {
    display: flex;
    flex-direction: column;
    font-size: 1.1vw;
}

.curriculum-accodrion-content {
    color: #393939;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
    font-family: "Poppins-Regular";
    font-size: 1.1vw;
}

.curriculum-accodrion-content.curriculum-accodrion-show {
    padding-top: 0.5vw;
    height: auto;
    max-height: 9999px;
    transition: all 1.2s cubic-bezier(0, 1, 0, 1);
}

@media (max-width: 600px) {
    .curriculum-accordion-item {
        margin-bottom: 2.5vw;
        padding: 1.5vw 2vw;
        border: 1px solid rgba(25, 29, 35, 1) 20;
        background-color: #f7f8f9;
        border-radius: 5px;
    }
    .curriculum-accodrion-title h2,
    .curriculum-accodrion-content {
        font-size: 4vw;
        padding: 0vw;
        margin: 0;
    }
    .curriculum-accodrion-title h2 {
        width: 206px;
    }
    .curriculum-accordion-img-wrapper {
        width: 100%;
    }
    .curriculum-accordion-answer {
        font-size: 3vw;
    }

    .accordian-point-img-wrapper img {
        width: 6.5%;
        margin: 1vw;
    }

    .accordian-point-img-wrapper p {
        font-size: 3.6vw;
        padding: 1vw 0;
    }
    .accordion-module {
        padding: 1.5vw 2vw;
        width: 24%;
        font-size: 3vw;
    }
    .open-close-img {
        width: 90%;
    }
    .open-close-img-close {
        width: 100%;
        margin-right: 1vw;
    }
    .curriculum-accodrion-with-dropdown span {
        margin-right: 0.5vw;
    }
}
