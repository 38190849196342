.about-event-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4vw 6vw;
}
.about-event-wrapper > h1 {
    text-align: center;
    font-family: Poppins;
    font-size: 2.8vw;
    font-weight: 700;
    color: #231f20;
    padding: 3vw;
    padding-top: 0vw;
}

.about-event-time-wrapper {
    width: 67%;
    margin-top: 4.5vw;
    padding: 1.5vw 2vw;
    border: none;
    background: #e8edfb;
    border: 1px solid #191d23;
    border-radius: 7px;
    font-family: Poppins;
    font-size: 1.3vw;
    font-weight: 700;
    color: #231f20;
}
.book-consultation-wrapper {
    width: 60%;
    margin-top: 4.5vw;
    padding: 1vw 2vw;
    border: none;
    background: #e8edfb;
    border-radius: 7px;
    font-family: Poppins;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3vw;
    font-weight: 700;
    color: #231f20;
}
.book-consultation-wrapper span {
    margin-right: 10px;
}
.about-event-points-wrapper {
    display: flex;
    flex-direction: column;
    width: 80%;
    text-align: justify;
    font-size: 1.3vw;
    font-family: Poppins-Regular;
}
.about-event-register-btn {
    padding: 2vw;
}
.about-event-register-btn button {
    padding: 1.2vw 6vw;
    font-size: 1.3vw;
    font-family: Poppins;
    border: none;
    border-radius: 1vw;
    background: #3a51a6;
    color: #fff;
    cursor: pointer;
}

@media (max-width: 660px) {
    .about-event-wrapper > h1 {
        font-size: 5.5vw;
        padding-bottom: 7vw;
    }
    .about-event-points-wrapper {
        font-size: 3.8vw;
        line-height: 6vw;
        width: 95%;
    }

    .about-event-time-wrapper {
        padding: 15px;
        font-size: 3.6vw;
        padding: 15px 12px;
        width: 100%;
    }

    .book-consultation-wrapper {
        padding: 15px;
        font-size: 14px;
        padding: 15px 0px;
        width: 100%;
    }

    .about-event-wrapper {
        text-align: center;
        padding-top: 45px;
        margin-bottom: 40px;
    }
    .about-event-register-btn {
        display: flex;
        width: 100%;
        margin-top: 3vw;
        padding: 0;
    }
    .about-event-register-btn > button {
        padding: 3.6vw 0vw;
        margin-top: 1.5vw;
        font-size: 3.6vw;
        width: 100%;
    }
}
