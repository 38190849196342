.footer-make-your-own-title-free-trial-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3vw;

    margin-top: 2vw;
    margin-bottom: 2vw;
}

.footer-make-your-own-title-text {
    font-size: 2vw;
    color: #4f516b;
    font-family: "Poppins-SemiBold";
    margin-bottom: 1vw;
}

.footer-start-free-trial-text {
    margin-bottom: 1.3vw !important;
    font-size: 1.6vw !important;
    color: #4359af;
    font-family: "Poppins-Medium";
}

.footer-free-trial-email-box-wrapper {
    margin-top: 1.1%;
    display: flex;
    flex-direction: row;
    background: #fafafb;
    padding: 0.2vw;
    border: 1px solid #ebecff;
    border-radius: 5px;
    width: 47%;
    margin-bottom: 2.5vw;
}

.footer-start-free-trial-email-box {
    flex-shrink: 1;
    width: 60%;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 0 3%;
}

.footer-start-free-trial-button {
    flex-shrink: 2;
    background: linear-gradient(93.44deg, #444bab -6.8%, #3ca5c2 186.94%);
    border-radius: 5px;
    font-size: 1.5vw !important;
    font-family: "Poppins-SemiBold";
    font-style: normal;
    font-weight: 500;
    line-height: 20% !important;
    cursor: pointer;
    text-align: center;
    color: #ffffff;
    width: 18% !important;
    padding: 2% 0 !important;
    margin: 0.5% 0.5%;
    outline: none;
    border: none;
}

.footer-free-trial-email-box-wrapper > input {
    border: 0;
    padding: 0.5vw 1.5vw;
    font-size: 1.3vw;
    background: #fafafb;
    font-family: "Poppins-Regular";
    color: #afacac;
}

.footer-start-free-trial-text {
    font-size: 4vw;
    margin-bottom: 6vw;
}

.footer-start-free-trial-text {
    font-size: 4vw;
    margin-bottom: 3vw;
}

.footer-start-free-trial-button {
    padding: 3% 0;
    font-size: 4vw;
    width: 45%;
}

.footer-start-free-trial-button {
    padding: 4% 0;
    font-size: 3vw;
}

.footer-free-trial-email-box-wrapper > input {
    padding-left: 3vw;
    font-size: 3vw;
}

@media (max-width: 600px) {
    .footer-make-your-own-title-text {
        font-size: 5vw;
    }
    .footer-start-free-trial-text {
        font-size: 4vw !important;
        padding: 10px 0;
    }
}
