.other-events-section {
    padding: 0vw 8vw;
    padding-bottom: 0;
}
.other-events-header-wrapper {
    padding-bottom: 3vw;
}

.other-events-header-wrapper p {
    color: #231f20;
    font-size: 2.8vw;
    font-family: Poppins;
    font-weight: 600;
    text-align: center;
    margin: 3vw 0vw;
}

.other-events-card {
    width: 90% !important;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    margin-bottom: 5vw;
}

.other-events-card-img-wrapper {
    position: relative;
}

.other-events-card-img {
    width: 100%;
    height: 100%;
}
.other-events-card-content {
    padding: 1.5vw;
    padding-bottom: 0vw;
}
.other-events-card-content h3 {
    color: #272834;
    font-family: "Lato-Bold";
    font-size: 1.5vw;
    margin-bottom: 1vw;
}
.other-events-date-time-wrapper {
    display: flex;
}
.starts-on-text {
    color: #717171;
    font-size: 1.2vw;
    padding-right: 0.5vw;
    padding-bottom: 0.2vw;
}

.slick-next {
    width: 40px;
    height: 40px;
    right: -60px;
}

.slick-prev {
    width: 40px;
    height: 40px;
    left: -60px;
}
@media (max-width: 600px) {
    .other-events-card-content {
        padding: 14px;
    }
    .other-events-card-content h3 {
        font-size: 14px;
    }
    .starts-on-text {
        font-size: 12px;
        padding-top: 2px;
    }

    .slick-next {
        width: 20px;
        height: 20px;
        right: -20px;
    }

    .slick-prev {
        width: 20px;
        height: 20px;
        left: -20px;
    }
    .other-events-header-wrapper p {
        font-size: 5vw;
    }
}
