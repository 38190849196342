.eventpage-wrapper {
    font-family: Poppins-Regular;
    color: #272834;
    display: flex;
    padding: 5vh 6vw;
}

.event-intro-wrapper {
    background: linear-gradient(182.17deg, #d7edfd 1.72%, rgba(252, 254, 255, 0) 82.82%);
}
.eventpage-header {
    font-family: Lato-Bold;
    font-size: 2.2vw;
    color: #231f20;
    padding: 1vw 7.5vw;
    padding-top: 5vw;
}
.event-intro-description {
    font-family: Lato-Regular;
    font-size: 1.3vw;
    color: 363636;
    padding: 1vw 7.5vw;
    padding-bottom: 4vw;
}
.eventpage-cards-wrapper {
    display: flex;
    margin-bottom: 3vh;
    flex-wrap: wrap;
}

.eventpage-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3vw;
}

.previous-events {
    display: flex;
    justify-content: center;
}
.css-yx0nvq-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover {
    background-color: #d2d4ee !important;
}

.previous-events-button {
    font-family: Poppins !important;
    font-style: normal !important;
    border: 2px solid #444bab !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    border-color: #444bab !important;
    color: #444bab !important;
}

.no-upcomming-events {
    margin-top: 50px;
}

.no-events-comment {
    text-align: center;
    padding: 2vw;
    font-family: Poppins-Regular;
    font-size: 1vw;
    color: #4a4a53;
}

@media (max-width: 660px) {
    .eventpage-header {
        font-size: 4vw;
        padding-bottom: 4vw;
    }

    .previous-events {
        font-size: 4vw !important;
    }
    .eventpage-wrapper {
        padding: 3vh 6vw;
    }
    .css-w05zow-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover {
        background-color: #d2d4ee !important;
    }
    .no-events-comment {
        font-size: 3vw;
    }
    .event-intro-description {
        font-size: 14px;
       text-align: justify;
    }
    .event-intro-wrapper {
        background: linear-gradient(182.17deg, #d7edfd 1.72%, rgba(252, 254, 255, 0) 102.82%);
    }
}
