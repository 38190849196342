.earn-coins-title-wrapper{
    display:flex;
    align-items: center;
    justify-content: center;
    padding-top: 3vw;
    padding-bottom: 1.5vw;
    position: relative;
    
    
}

.earn-coins-title-wrapper h3 {
    font-family: Lato-Bold;
    font-weight: 800;
    font-size: 3vw;
    color: #231f20;
    
}
.earn-coins-title-wrapper p{
    font-family: Lato-Bold;
    font-size: 2vw;
    color: #231f20;
    text-align: right;
    margin-left: 5vw;
    position: absolute;
    right:3vw;
}
.earn-coins-wrapper {
    background: linear-gradient(180deg, #d9f3fa 8.11%, #15728c 123.09%);
    padding: 0 4vw;
}
.coin-refferal-to-point-conversion {
    text-align: center;
    padding-bottom: 2vw;
    font-size: 1.3vw;
    font-weight: 700;
    font-family: Lato-Bold;
}
.refer-gift-cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}
.refer-gift-card {
    padding: 1vw;
    width: 25%;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 0.5vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 2vw;
    margin-bottom: 5vw;
}
.card-details-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 0.5vw;
    align-items: center;
}
.card-coin-box {
    display: flex;
    align-items: center;
    background: #231f20;
    border-radius: 0.4vw;
    color: #fff;
    font-family: Lato-Bold;
    padding: 0.5vw;
}
.card-coin-box > p {
    padding-right: 0.5vw;
}
.refer-card-desc {
    font-family: Lato-Bold;
    color: #231f20;
    font-size: 1.2vw;
}
.gift-image-wrapper > img {
    width: 100%;
    height: auto;
}
@media (max-width: 600px) {
    .refer-gift-cards-wrapper {
        flex-direction: column;
        padding: 0 8vw;
        padding-top: 6vw;
    }
    .refer-gift-card {
        width: 95%;
        padding: 3vw;
        border-radius: 1vw;
    }
    .refer-card-desc {
        font-size: 3.5vw;
    }
    .card-coin-box {
        padding: 2vw;
        border-radius: 1vw;
        margin-top: 2vw;
    }
    .coin-refferal-to-point-conversion {
        font-size: 15px;
    }
    .earn-coins-title-wrapper h3{
        font-size: 5vw;
    }
    .earn-coins-title-wrapper{
        padding-top:8vw;
    }
}
