.pricing-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #e8edfb;
    padding-bottom: 2vw;
}

.pricing-card {
    background: #ffffff;
    padding: 3.5vw 2.5vw;
    border-radius: 8px;
    width: 45%;
}
.pricing-heading {
    font-family: Poppins;
    font-size: 1.1vw;
    margin-top: 1.5vw;
    margin-bottom: 1vw;
    padding: 0.6vw 2.2vw;
    display: flex;
    justify-content: center;
    border-radius: 2vw;
    background: #fee2e2;
    color: #dc2626;
    width: 25%;
}

.pricing-card > p,
.event-applied-coupon-text {
    color: #10b981;
    font-family: Poppins;
    font-weight: 500;
}

.pricing-card > p {
    font-size: 1vw;
}
.event-applied-coupon-text {
    font-size: 1.1vw;
}

.event-topic-for-pricing {
    color: #191d23;
    font-weight: 600;
    font-family: Poppins;
    font-size: 2vw;
    padding-top: 1vw;
}

.event-features-heading {
    color: #191d23;
    font-family: Poppins-Semibold;
    font-size: 1.5vw;
    margin: 1vw 0vw;
}

.event-features {
    color: #191d23;
    font-family: Poppins-Regular;
    font-size: 1.2vw;
    margin-bottom: 1vw;
    display: flex;
    align-items: center;
}
.event-features > img {
    margin-right: 1vw;
    width: 3.5%;
}

.discounted-amount-pricing {
    color: #191d23;
    font-family: Poppins;
    font-size: 2.5vw;
    font-weight: 600;
    margin-right: 1vw;
    margin-top: 1.5vw;
}

.event-actual-price {
    color: #64748b;
    text-decoration: line-through;
    font-size: 2vw;
    font-family: Poppins;
    font-weight: 600;
    margin-right: 1vw;
    margin-top: 1.5vw;
}

.event-discount-percent {
    color: #ec732f;
    font-size: 2vw;
    font-family: Poppins;
    font-weight: 600;
    margin-top: 1.5vw;
}

.form-event-apply-coupon-input,
.modal-form-event-apply-coupon-input {
    position: relative;
    padding-top: 1.1vw;
    cursor: pointer;
    width: 100%;
}
.form-event-apply-coupon-input > input {
    padding: 1.35vw 0vw 1.35vw 1vw;
    font-size: 1.2vw;
}

.modal-form-event-apply-coupon-input > input,
.form-event-apply-coupon-input > input {
    width: 100%;
    border-radius: 1vw;
    background: rgba(247, 248, 249, 1);
    border: none;
    font-family: Poppins;
}

.modal-form-event-apply-coupon-btn,
.form-event-apply-coupon-btn {
    position: absolute;
    cursor: pointer;
    font-family: Poppins;
    color: #ffffff;
}

.form-event-apply-coupon-btn {
    right: 2%;
    top: 1.5vw;
    padding: 1vw 2vw;
    border-radius: 1vw;
    background: #3a51a6;
    font-size: 1.18vw;
}

.modal-form-event-apply-coupon-input > input {
    padding: 0;
    font-size: 0.9vw;
}

.modal-form-event-apply-coupon-btn {
    right: 1%;
    top: 1.4vw;
    background: rgba(97, 144, 232, 1);
    padding: 0.6vw 1.6vw;
    border-radius: 5px;
    font-size: 1.18vw;
    font-weight: 500;
}

.event-applied-coupon-text {
    margin-top: 1vw;
}
.time-span-wrapper {
    background: var(--Primary-Blue-50, #e8edfb);
    padding-bottom: 4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4vw;
}
.time-span-title {
    font-family: Poppins;
    font-weight: 700;
    font-size: 2.8vw;
    color: #191d23;
    text-align: center;
    padding: 3vw;
}
.grab-your-spot-title {
    color: #10b981;
}
.time-span-box {
    border-radius: 0.5vw;
    background-image: repeating-linear-gradient(
            to right,
            #10b981 0%,
            #10b981 50%,
            transparent 50%,
            transparent 100%
        ),
        repeating-linear-gradient(
            to right,
            #10b981 0%,
            #10b981 50%,
            transparent 50%,
            transparent 100%
        ),
        repeating-linear-gradient(
            to bottom,
            #10b981 0%,
            #10b981 50%,
            transparent 50%,
            transparent 100%
        ),
        repeating-linear-gradient(
            to bottom,
            #10b981 0%,
            #10b981 50%,
            transparent 50%,
            transparent 100%
        );
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 20px 3px, 20px 3px, 3px 20px, 3px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2vw 1vw;
}
.time-and-date-value {
    font-family: Poppins;
    font-size: 4.1vw;
    font-weight: 700;
    color: #10b981;
    padding-right: 1vw;
}
.time-and-date-name {
    font-family: Poppins;
    font-size: 1.2vw;
    color: #191d23;
    margin-left: 0.4vw;
}
.register-button-and-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.time-span-register-now-cta {
    background: var(--Gradient-Color-1, linear-gradient(180deg, #3940a0 0%, #3ca3c1 100%));
    font-family: Poppins;
    color: #fff;
    padding: 0.8vw 3vw;
    font-size: 1.3vw;
    border-radius: 0.5vw;
    border: none;
    margin: 2vw 0;
    width: fit-content;
    cursor: pointer;
    width: 100%;
}
.enrolled-people {
    display: flex;
    align-items: center;
}
.enrolled-people-text {
    font-family: Poppins;
    color: #10b981;
    font-size: 1.3vw;
}
.enrolled-people img {
    padding-right: 1vw;
}

.reg-form-modal-wrapper-bg {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2vw 0vw;
}
.reg-form-modal-wrapper {
    position: relative;
    width: 45%;
    top: -2vw;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 2vw;
    pointer-events: auto;
}

.reg-now-close-icon-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2vw;
}
.close-icon {
    width: 1.3rem;
    height: 1.3rem;
    cursor: pointer;
}
.event-register-form {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid #cecece;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    padding: 2vw 3vw;
}
.event-register-form-heading {
    font-family: Poppins;
    font-weight: bold;
    font-weight: 700;
    color: #191d23;
    font-size: 1.5vw;
}
.event-register-form input,
select {
    background: #fff;
    border: 1px solid #d0d5dd;
    border-radius: 6px;
    width: 100%;
    padding: 0.95vw 0;
    color: #191d23;
    text-indent: 10px;
    cursor: pointer;
    font-size: 1.15vw;
}
.event-register-form label {
    padding-bottom: 1vw;
}
.event-register-form option {
    background-color: #d0d5dd;
    padding: 1vw 0;
    cursor: pointer;
}
.event-register-form select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 1vw 0;
    cursor: pointer;
}
.event-register-form option {
    font-size: 1.1vw;
    padding: 1vw 0;
    cursor: pointer;
}
.form-share-and-referrel-btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 1vw;
}
.register-or-pay-btn-wrapper {
    width: 100%;
    font-family: Poppins;
    color: #ffffff;
    background: linear-gradient(93.53deg, #444bab 0%, #416bb3 62.5%, #3ca5c2 100%);
    font-size: 1.4vw;
    padding: 0.5vw;
    border-radius: 6px;
    margin: 2vw 0;
    margin-bottom: 1vw;
    cursor: pointer;
    text-align: center;
    padding: 1vw 0vw;
}
.error-text {
    font-family: Poppins;
    font-size: 1.1vw;
    padding: 0.8vw;
    color: #d31009;
    text-align: center;
    padding-top: 0.5vw;
}

.event-amount-wrapper {
    display: flex;
    width: 100%;
}

.form-share-btn {
    font-family: Poppins;
    font-weight: bold;
    color: #6190e8;
    background: none;
    border: none;
    font-size: 1.25vw;
    text-decoration: underline;
}

.success-modal-wrapper-bg {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.success-modal-wrapper {
    position: relative;
    width: 50%;
    background: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 3%;
    pointer-events: auto;
}

.success-tick {
    width: 10%;
    height: 10%;
}
.success-modal-close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}
.success-modal-heading {
    text-align: center;
    color: #4f516b;
    font-family: "Lato-Bold";
    margin: 3% 0;
    font-size: 2vw;
}
.success-modal-message {
    font-family: "Lato-Regular";
    font-size: 1.3vw;
    text-align: center;
    color: #656565;
    margin-bottom: 1.5vw;
}
.success-modal-button {
    font-family: "Lato-Bold";
    background: linear-gradient(93.44deg, #444bab -6.8%, #3ca5c2 186.94%);
    border-radius: 6px;
    color: #ffff;
    cursor: pointer;
    text-align: center;
    font-family: Poppins-SemiBold;
    padding: 0.5vw 3vw;
}

.share-modal-wrapper-bg {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.share-modal-wrapper {
    position: relative;
    width: 50%;
    background: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 3% 0%;
    pointer-events: auto;
}
.share-modal-input-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    padding: 2vw;
    padding-bottom: 0vw;
}
.share-modal-input-wrapper input {
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    width: 100%;
    padding: 1vw 0;
    text-indent: 10px;
    cursor: pointer;
    color: #272834;
    font-family: Lato-Bold;
}
.share-modal-input-copy {
    position: absolute;
    left: 87%;
    top: 47%;
    color: #2a329d;
    font-weight: 700;
    font-size: 1.3vw;
    cursor: pointer;
    padding-left: 0.2vw;
}
.share-modal-heading {
    font-family: Lato-Bold;
    font-size: 2vw;
    text-align: center;
    padding: 2vw 6vw;
}
.share-icons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.share-icon-img {
    width: 7vw;
    padding: 0vw 1vw;
    cursor: pointer;
}
.share-word {
    padding: 2vw;
    font-family: Lato-Bold;
    font-size: 1.7vw;
    color: #4f516b;
}

.pricing-referral-cashback{
    font-family: Poppins-Medium;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.0125em;
    margin-bottom: 20px;
}
.pricing-cashback-amount{
    font-family: Poppins-Medium;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #F97316;
    letter-spacing: 0.0125em;
}

@media (max-width: 600px) {
    .pricing-wrapper {
        margin-top: 40px;
        background: #ffffff;
    }

    .reg-form-modal-wrapper {
        width: 90%;
    }
    .pricing-card {
        padding: 5vw 8vw;
        width: 100%;
    }
    .pricing-heading {
        font-size: 3.5vw;
        margin-top: 4vw;
        margin-bottom: 3vw;
        padding: 1.4vw 8vw;
        border-radius: 6vw;
        width: 35%;
    }
    .time-span-title {
        font-size: 5.5vw;
        padding: 5vw 20vw;
    }
    .time-span-box {
        width: 100%;
        border-radius: 1.3vw;
        padding: 8vw 0;
    }
    .time-span-wrapper {
        padding: 0 6vw;
        padding-bottom: 5vw;
    }
    .time-and-date-value {
        font-size: 8vw;
        padding-right: 1vw;
    }
    .time-and-date-name {
        font-size: 3vw;
        padding: 1vw 0;
        padding-left: 1vw;
    }
    .time-span-register-now-cta {
        width: 100%;
        padding: 3.6vw 0vw;
        font-size: 3.6vw;
        margin: 7vw 0;
        margin-bottom: 4vw;
        border-radius: 1.2vw;
        border: none;
    }
    .enrolled-people-text {
        font-size: 3vw;
    }
    .entrolled-people {
        font-size: 3.5vw;
    }
    .share-modal-wrapper {
        width: 80%;
        padding: 5% 1%;
    }
    .share-modal-input-wrapper input {
        padding: 1vw;
        font-size: 3.5vw;
    }
    .share-modal-input-copy {
        font-size: 3.5vw;
    }
    .share-modal-heading {
        font-size: 5vw;
        padding: 4vw 8vw;
    }
    .success-modal-wrapper {
        width: 85%;
    }
    .success-modal-heading {
        font-size: 4vw;
    }

    .success-modal-message {
        font-size: 3vw;
        padding-bottom: 2.5vw;
    }

    .success-modal-button {
        padding: 2vw 4vw;
        font-size: 3.5vw;
    }
    .share-word {
        font-size: 3vw;
        padding: 4vw;
    }
    .share-icons-wrapper {
        padding: 2.5vw;
    }
    .share-icon-img {
        width: 12vw;
    }
    .react-tel-input {
        margin-bottom: 10px;
    }
    .event-register-form {
        padding: 4vw;
    }
    .event-register-form-heading {
        padding-bottom: 5%;
        font-size: 5vw;
    }
    .event-register-form input,
    select {
        padding: 4vw 0;
        margin-bottom: 3vw;
        font-size: 15.5px;
    }
    .register-or-pay-btn-wrapper {
        padding: 3.6vw 0;
        font-size: 3.6vw;
        margin: 4vw 0;
    }
    .form-share-btn {
        font-size: 4.5vw;
        padding-bottom: 3vw;
    }

    .event-details-register-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .event-details-register-wrapper > p {
        font-size: 4vw;
    }
    .event-details-register-wrapper > img {
        width: 6vw;
    }

    .pricing-card > p,
    .event-applied-coupon-text {
        font-size: 3vw;
    }

    .event-topic-for-pricing {
        font-size: 4.1vw;
    }

    .event-features-heading {
        font-size: 4vw;
        margin: 3vw 0vw;
    }

    .event-features {
        font-size: 3.8vw;
        margin-bottom: 2vw;
    }
    .event-features > img {
        margin-right: 1.5vw;
        width: 5.6%;
    }
    .event-features-points {
        margin-bottom: 4vw;
    }

    .discounted-amount-pricing {
        font-size: 5vw;
        margin-right: 1.5vw;
    }

    .event-actual-price {
        font-size: 5vw;
        margin-right: 1.5vw;
    }

    .event-discount-percent {
        font-size: 5vw;
    }
    .form-event-apply-coupon-input,
    .modal-form-event-apply-coupon-input {
        padding-top: 1vw;
    }
    .form-event-apply-coupon-input > input,
    .modal-form-event-apply-coupon-input > input {
        padding: 2.6vw 2vw 2.6vw 2vw;
        border-radius: 1.5vw;
        font-size: 3.5vw;
    }

    .event-applied-coupon-text {
        margin-top: 1vw;
    }

    .form-event-apply-coupon-btn {
        top: 2vw;
        padding: 2vw 3vw;
        border-radius: 1vw;
        font-size: 3.1vw;
    }

    .modal-form-event-apply-coupon-btn {
        top: 2.2vw;
        padding: 2.1vw 3vw;
        font-size: 3.1vw;
    }
}
