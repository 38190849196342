.previousevents-wrapper {
    font-family: Poppins-Regular;
    color: #272834;
}

.previousevents-header {
    font-family: Poppins-SemiBold;
    font-size: x-large;
    margin-bottom: 1.3vw;
    margin-top: 10vh;
}

.previousevents-cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 0 6vw;
}

.previousevents-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2vw 0;
}

.upcoming-events {
    display: flex;
    justify-content: center;
    padding-top: 6vw;
}

.upcoming-events-button {
    font-family: Poppins !important;
    border: 2px solid #444bab !important;
    font-weight: bold !important;
    text-transform: capitalize !important;
    border-color: #444bab !important;
    color: #444bab !important;
}

@media (max-width: 660px) {
    .previousevents-header {
        font-size: 6vw;
    }

    .upcoming-events-button {
        font-size: 4vw !important;
    }
}
