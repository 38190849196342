.eventcard-wrapper {
    width: 47%;
    background: #ffffff;
    border: 1px solid #d3cbcb;
    border-radius: 8px;
    margin: 1.5%;
    position: relative;
}

.eventcard-image-wrapper {
    position: relative;
    display: flex;
}

.paid-sticker {
    position: absolute;
    left: 37vw;
    top: 0vw;
    width: 25%;
}
.paid-sticker > img {
    width: 30%;
}

.recorded-on-wrapper {
    width: 7%;
    padding: 0.5vw;
    margin-top: 0.5vw;
}
.recorded-on-wrapper img {
    width: 100%;
}
.eventcard-image-wrapper > img {
    width: 100%;
    height: 100%;
}

.eventcard-info-wrapper {
    padding: 1.5vw;
    padding-bottom: 0vw;
}

.eventcard-info {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.event-card-header {
    width: 95%;
    font-family: Lato-Bold;
    font-size: 1.5vw;
    margin-bottom: 1vh;
    color: #272834;
}

.eventcard-datetime {
    display: flex;
    color: #717171;
    padding: 0vw 0;
    align-items: center;
}
.eventcard-datetime p {
    font-size: 1.1vw;
    padding-right: 0.5vw;
    font-family: Lato-Regular;
}
.prev-btn-wrapper {
    display: flex;
}
.get-recorded-video-btn-wrapper {
    width: 93%;
    background: linear-gradient(93.53deg, #444bab 0%, #416bb3 62.5%, #3ca5c2 100%);
    font-family: "Poppins-SemiBold";
    text-transform: capitalize;
    padding: 1vw 1.5vw;
    font-size: 1.2vw;
    font-family: Lato-Bold;
    white-space: nowrap;
    border: none;
    color: #fff;
    border-radius: 0.4vw;
}
.font-grey {
    font-size: 1.2vw;
    padding: 0.1vw;
    color: #717171;
    font-family: Lato-Regular;
}
.font-black {
    color: #231f20;
    font-weight: 500;
    font-family: Lato-Regular;
}
.eventcard-reg-wrapper {
    padding-top: 1vw;
    display: flex;
}
.get-recorded-video-btn-wrapper {
    position: absolute;
    bottom: 4%;
}
.prev-datetime {
    padding-bottom: 5vw;
}

.eventcard-regbutton-wrapper {
    padding-right: 2vw;
}

.share-event-modal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.share-button-wrapper {
    object-fit: cover;
}
.eventcard-shareicon {
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    padding: 0.5vw;
    box-shadow: 0px 0.25px 4px rgba(0, 0, 0, 0.25);
    width: 2.5vw;
    height: 2.5vw;

    position: absolute;
    top: 1vw;
    right: 1vw;
}
.eventcard-header-wrapper > h3 {
    color: #272834;
    font-family: "Lato-Bold";
    font-size: 1.5vw;
    margin-bottom: 1vw;
}

.recommended-for {
    color: #086478;
    font-family: Lato-Italic;
    padding: 1vw 0.5vw;
    font-weight: 600;
}
@media (max-width: 600px) {
    .eventcard-wrapper {
        flex-direction: column;
        width: 100%;
    }
    .eventcard-image-wrapper {
        border-radius: 12px 12px 0 0;
        width: 100%;
        height: auto;
    }
    .eventcard-info-wrapper {
        width: 100%;
        padding: 3vw 2vw;
    }
    .eventcard-header-wrapper {
        margin-bottom: 2vw;
    }
    .event-card-header {
        width: 100%;
        font-size: 4vw;
        margin-bottom: 1vh;
    }
    .eventcard-description {
        width: 100%;
        font-size: 3vw;
    }
    .eventcard-datetime > div {
        margin-right: 1vw;
    }
    .eventcard-reg-wrapper {
        max-height: 10vw;
    }

    .eventcard-shareicon {
        padding: 1vw;
        width: 5vw;
        height: 5vw;
        top: 2vw;
        right: 2vw;
    }
    .eventcard-header-wrapper > h3 {
        font-size: 4vw;
    }
    .eventcard-datetime {
        padding: 1.5vw 0;
    }
    .eventcard-datetime > p {
        font-size: 3vw;
    }
    .font-black,
    .font-grey {
        font-size: 12px;
    }

    .paid-sticker {
        top: 0%;
        left: 85%;
    }
    .eventcard-datetime p {
        font-size: 14px;
    }

    .recommended-for{
        font-size: 12px;
        padding-top: 12px;
    }
}
